import dayjs from "dayjs";
import { setClientSideExceptionsByProgram, setClientSideExceptionsCountByDate, setExByDates, setExByMessages, setExByPlugins, setExByPrograms, setExMessagesByPluginName, setPluginNameByCount } from "../../store/crmSlice";
import Store from "../../store/store";

//Method  to get "Exception count by Program"
export const getExCountByProgram = (exceptionsResult: [], allStateCodes: []) => {
    let frequencyMap: any = {}
    var ex: any = [];
    let programExData: any = {}

    exceptionsResult?.forEach((item: any) => {
        allStateCodes?.forEach((elem: any) => {
            if (elem.organizationId === item.organizationId) {
                programExData = { ...elem };
                ex.push(programExData)
            }
        })
    })
    ex.forEach((item: any) => {
        if (item.abbreviation) {
            if (frequencyMap[item.abbreviation]) {
                frequencyMap[item.abbreviation] += 1
            } else {
                frequencyMap[item.abbreviation] = 1
            }   
        }
    })
   
    const data: any = Object.entries(frequencyMap).map(([state, count]) => ({ state, count }));
    Store.dispatch(setExByPrograms(data));
}

//Method  to get "Exception count by Message"
export const getExMessage = (result: []) => {
    let messageCountMap: any = {}

    result.forEach((item: any) => {
        const exceptionMessage = item.exceptionMessage;
        if (!messageCountMap[exceptionMessage]) {
            messageCountMap[exceptionMessage] = 0;
        }
        messageCountMap[exceptionMessage]++;
    });
    const data: any = Object.entries(messageCountMap).map(([name, value]) => ({ name, value }));
    Store.dispatch(setExByMessages(data));
}

//Method  to get "Exception count by Plugin/Date LineChart"
export const getPluginCountByDate = (result: []) => {
    let pluginCountMap: any = {}

    result.forEach((item: any) => {
        const date = dayjs(item.timeGenerated).format('MM/DD');

        if (!pluginCountMap[date]) {
            pluginCountMap[date] = {};
        }

        const pluginName = item.pluginName;
        if (!pluginCountMap[date][pluginName]) {
            pluginCountMap[date][pluginName] = 0;
        }
        pluginCountMap[date][pluginName]++;
    });
    const chartData: any = Object.keys(pluginCountMap).map(date => {
        const entry: any = { date };
        Object.keys(pluginCountMap[date]).forEach((key) => {
            entry[key] = pluginCountMap[date][key];
        });
        return entry;
    });
    const sortedChartData = chartData.sort((a: any, b: any)=> Date.parse(a.date) - Date.parse(b.date));
    Store.dispatch(setExByPlugins(sortedChartData));
    getExMessageByPluginName(result);
}

//Method  to get "Exception count by Date"
export const getExCountByDate = (result: []) => {
    let dateCountMap: any = {}
    result?.forEach((item: any) => {
        const date = dayjs(item.timeGenerated).format('MM/DD');

        if (!dateCountMap[date]) {
            dateCountMap[date] = 0;
        }
        dateCountMap[date]++;
    })
    const data: any = Object.keys(dateCountMap).map(item => {
        return {
            date: item,
            count: dateCountMap[item]
        }
    })
    const sortedChartData = data.sort((a: any, b: any)=>  Date.parse(a.date) - Date.parse(b.date));

    Store.dispatch(setExByDates(sortedChartData));
}

//Method  to get "Exception count by Plugin/Date PieChart"
export const getPluginCountByDatePieChart = (result: []) => {
    let pluginCountMap: any = {}
    result.forEach((item: any) => {

        const pluginName = item.pluginName;

        if (!pluginCountMap[pluginName]) {
            pluginCountMap[pluginName] = 0;
        }

        pluginCountMap[pluginName]++
    });
    const data: any = Object.entries(pluginCountMap).map(([name, value]) => ({ name, value }));

    Store.dispatch(setExByPlugins(data));
    getExMessageByPluginName(result);
}

//Method  to get "Plugin name by Count"
export const getPluginNameByCount = (result: []) => {
    let pluginCountMap: any = {}
    result.forEach((item: any) => {

        const pluginName = item.pluginName;

        if (!pluginCountMap[pluginName]) {
            pluginCountMap[pluginName] = 0;
        }

        pluginCountMap[pluginName]++
    });
    const data: any = Object.entries(pluginCountMap).map(([pluginName, count]) => ({ pluginName, count }));
    Store.dispatch(setPluginNameByCount(data))
}

//To show the exception messages on piechart on click of each line/pie data 
const getExMessageByPluginName = (result: []) => {
    let pluginCountMap: any = {}
    result.forEach((item: any) => {

        const pluginName = item.pluginName;
        const exceptionMessage = item.exceptionMessage;

        if (!pluginCountMap[pluginName]) {
            pluginCountMap[pluginName] = {};
        }

        if (!pluginCountMap[pluginName][exceptionMessage]) {
            pluginCountMap[pluginName][exceptionMessage] = 0;
        }

        pluginCountMap[pluginName][exceptionMessage]++
    });
    
    Store.dispatch(setExMessagesByPluginName(pluginCountMap));
}

//ClientSideException methods
export const getClientSideExByPrgram = (exceptionsResult: [], allStateCodes: []) => {
    let frequencyMap: any = {}
    var ex: any = [];
    let programExData: any = {}

    exceptionsResult?.forEach((item: any) => {
        allStateCodes?.forEach((elem: any) => {
            if (elem.organizationName === item.organizationName) {
                programExData = { ...elem };
                ex.push(programExData)
            }
        })
    })
    ex.forEach((item: any) => {
        if (item.abbreviation) {
            if (frequencyMap[item.abbreviation]) {
                frequencyMap[item.abbreviation] += 1
            } else {
                frequencyMap[item.abbreviation] = 1
            }   
        }
    })
   
    const data: any = Object.entries(frequencyMap).map(([state, count]) => ({ state, count }));
    Store.dispatch(setClientSideExceptionsByProgram(data));
}

export const getClientSideExCountByDate = (result: []) => {
    let dateCountMap: any = {}
    result?.forEach((item: any) => {
        const date = dayjs(item.timeGenerated).format('MM/DD');

        if (!dateCountMap[date]) {
            dateCountMap[date] = 0;
        }
        dateCountMap[date]++;
    })
    const data: any = Object.keys(dateCountMap).map(item => {
        return {
            date: item,
            count: dateCountMap[item]
        }
    })
    const sortedChartData = data.sort((a: any, b: any)=>  Date.parse(a.date) - Date.parse(b.date));

    Store.dispatch(setClientSideExceptionsCountByDate(sortedChartData));
}

export const getErrorCountByErrMsgDateOrgName = (result: any) => {
    let pluginCountMap: any = {}
    result?.forEach((item: any) => {

        const date = dayjs(item.timeGenerated).format('MM/DD/YYYY');
        const exceptionMessage = item.errorMessage;
        const orgName = item.organizationName

        const key = `${date}-${orgName}-${exceptionMessage}`;
    
        if (pluginCountMap[key]) {
            pluginCountMap[key]++;
        } else {
            pluginCountMap[key] = 1;
        }
    });

}
