import { Box, Typography } from "@mui/material"
import React from "react"
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, Rectangle } from "recharts"

interface exceptionChartProps {
    data: any[], 
    label: string, 
    xAxisKey?: string, 
    dataKey: string, 
    children?: any,
    showCustomTooltip? : boolean,
    textAlign?: any
}

const renderTooltip = ({ active, payload, label }: any) => {

    if (active && payload && payload.length) {
      return <Box sx={{
        backgroundColor: "white",
        borderColor: "primary.main",
        borderRadius: 2,
        borderStyle: "solid",
        borderWidth: 1,
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        p: 2
      }}>
         <Typography color={'#00C49F'}>{`${payload[0]?.payload?.pluginName} : ${payload[0].value}` }</Typography>
      </Box>
    }
    return null;
  }

const CrmExceptions: React.FC<exceptionChartProps> = ({data, label, xAxisKey, dataKey, children, showCustomTooltip, textAlign='center'}) => {
    return <Box>
    <Typography variant="h6" component="h1" py={2} textAlign={textAlign}>
        {label}
    </Typography>
    <BarChart width={700}
        height={300}
        data={data}
        maxBarSize={40}
        margin={{
            top: 10,
            right: 30,
            bottom: 5,
        }}>

        <CartesianGrid strokeDasharray="3 3" />

        <XAxis dataKey={xAxisKey} />
        <YAxis />
        {
            showCustomTooltip ? <Tooltip content={renderTooltip}/> : <Tooltip />
        }
        <Bar
            dataKey={dataKey}
            fill="#2d607f"
            activeBar={<Rectangle fill=" #357197" />}
        >
            {children}
        </Bar>
    </BarChart>
</Box>
}

export const CrmExceptionsChart = React.memo(CrmExceptions);