import { Box, Typography } from '@mui/material';
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
const _ = require("lodash");

interface chartProps {
  data: any,
  onClick?: (item: any) => void
}

const LineChartException: React.FC<chartProps> = ({ data, onClick }) => {

  const COLORS = [
    '#0088FE', '#00C49F', '#FFBB28', '#FF8042',
    '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
    '#1976d2', '#1565c0', '#0d47a1', '#82b1ff',
    '#448aff', '#2979ff', '#2962ff'
  ];

  console.log('data ',data)

  const onPointClick = (item: any) => {
    console.log('item ', item)
    onClick && onClick(item);
  }

  const renderTooltip = ({ active, payload, label }: any) => {

    if (active && payload && payload.length) {
      return <Box sx={{
        backgroundColor: "white",
        borderColor: "primary.main",
        borderRadius: 2,
        borderStyle: "solid",
        borderWidth: 1,
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        p: 2
      }}>
        {
          Object.entries(payload[0].payload).map(([name, value], index) => {
            return <Typography sx={{ display: 'flex', color: payload[index]?.color }}>{`${name} : ${value}`}</Typography>
          })
        }
      </Box>
    }
    return null;
  }


  return <LineChart
    width={800}
    height={600}
    data={data}
    margin={{
      top: 10,
      right: 30,
      bottom: 5,
    }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="date" />
    <YAxis />
    <Tooltip />
    <Legend />

    {
      // data.map((item: any, index: number) => {
      //   return Object.keys(item).map((key, idx) => {
      //     return key !== 'date' && <Line key={idx} type="monotone" dataKey={key} stroke={COLORS[idx]}  />
      //   })
      // })  
      Object.keys(data[0]).map((key, index) => key !== 'date' && <Line key={index} type="monotone" dataKey={key} stroke={COLORS[index]} onClick={() => console.log('on click')} />)
    }
    {/* {
      Object.keys(data[1]).map((key, index) => key !== 'date' && <Line key={index} type="monotone" dataKey={key} stroke={COLORS[index]}  />)
    } */}
  </LineChart>
}

export const LineChartComponent = React.memo(LineChartException);
