import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  flags: [],
  envDetails: {},
  isLoading: false,
  crmFF: '',
  tableLoading: false,
  unavailFlags: [],
  featFlagStateCodes: [],

  pluginExceptionsLoading: false,
  crmPluginStateCode: '',
  pluginExceptions: [],
  filteredPluginExceptions: [],
  exByProgram: [],
  exByDate: [],
  exByPlugin: [],
  exByMessage: [],
  exMessageByPluginName: [],
  fromDate: '',
  toDate: '',
  PlExceptionStateCodes: [],
  plExceptionStateCodesLoading: false,
  pluginNameByCount: [],
  plExStatusCode: 0,

  crmClientSideExceptions: [],
  clientSideExceptionsByProgram: [],
  clientSideExceptionsCountByDate: [],
  clientSideExceptionsLoading: false,
  filteredClientSideExceptions: [],
  crmClientSideStateCode: '',
  clientSideFromDate: '',
  clientSideToDate: '',

}

const crmSlice = createSlice({
    name: 'crm',
    initialState,
    reducers: {
        /* Feat.Flags operations */
        setCRMFlags: (state, action) => {
            state.flags = action.payload
        },
        setCRMEnvDetails: (state, action) => {
            state.envDetails = action.payload
        },
        setCRMLoader: (state, action) => {
            state.isLoading = action.payload
        }, 
        setCRMStateCode: (state, action) => {
            state.crmFF = action.payload
        },
        setCRMTableLoader: (state, action) => {
            state.tableLoading = action.payload
        },
        setUnavailableFlags: (state, action) => {
            state.unavailFlags = action.payload
        },
        setFeatFlagStateCodes: (state, action) => {
            state.featFlagStateCodes = action.payload
        },

         /* Plugin Exception operations */
        setPluginExceptionLoader: (state, action) => {
            state.pluginExceptionsLoading = action.payload
        }, 
        setPluginExceptionStateCode: (state, action) => {
            state.crmPluginStateCode = action.payload
        },
        setPluginExceptions: (state, action) => {
            state.pluginExceptions = action.payload
        },
        setFilteredPluginExceptions: (state, action) => {
            state.filteredPluginExceptions = action.payload
        },
        setExByPrograms: (state, action) => {
            state.exByProgram = action.payload
        },
        setExByDates: (state, action) => {
            state.exByDate = action.payload
        },
        setExByPlugins: (state, action) => {
            state.exByPlugin = action.payload
        },
        setExByMessages: (state, action) => {
            state.exByMessage = action.payload
        }, 
        setExMessagesByPluginName: (state, action) => {
            state.exMessageByPluginName = action.payload
        },
        setCRMFromDate: (state, action) => {
            state.fromDate = action.payload
        },
        setCRMToDate: (state, action) => {
            state.toDate = action.payload
        },
        setPlExceptionStateCodesLoader: (state, action) => {
            state.plExceptionStateCodesLoading = action.payload
        },
        setPluginNameByCount: (state, action) => {
            state.pluginNameByCount = action.payload
        },
        setStatusCode: (state, action) => {
            console.log('payload ',action.payload)
            state.plExStatusCode = action.payload
        },

        /* ClientSide Exception operations */
        setCRMClientSideExceptions: (state,action) => {
            state.crmClientSideExceptions = action.payload
        },
        setPlExceptionStateCodes: (state,action) => {
            state.PlExceptionStateCodes = action.payload
        },
        setClientSideExceptionsByProgram: (state, action) => {
            state.clientSideExceptionsByProgram = action.payload
        },
        setClientSideExceptionsCountByDate: (state, action) => {
            state.clientSideExceptionsCountByDate = action.payload
        },
        setClientSideExceptionLoader: (state, action) => {
            state.clientSideExceptionsLoading = action.payload
        }, 
        setFilteredClientSideExceptions: (state,action) => {
            state.filteredClientSideExceptions = action.payload
        },
        setClentSideExceptionStateCode: (state, action) => {
            state.crmClientSideStateCode = action.payload
        },
        setCRMClientSideFromDate: (state, action) => {
            state.clientSideFromDate = action.payload
        },
        setCRMClientSideToDate: (state, action) => {
            state.clientSideToDate = action.payload
        },
    }
})

export const { setCRMFlags, setCRMEnvDetails, setCRMLoader, setPluginExceptionLoader,
    setCRMStateCode, setCRMTableLoader, setUnavailableFlags, setPluginExceptionStateCode, 
    setPluginExceptions, setExByPrograms, setExByDates, setExByPlugins, setExByMessages, 
    setFilteredPluginExceptions, setExMessagesByPluginName, setCRMFromDate, setCRMToDate, 
    setCRMClientSideExceptions, setPlExceptionStateCodes, setClientSideExceptionsByProgram,
    setClientSideExceptionsCountByDate, setClientSideExceptionLoader, setFilteredClientSideExceptions,
    setClentSideExceptionStateCode, setCRMClientSideFromDate, setCRMClientSideToDate,
    setPlExceptionStateCodesLoader, setFeatFlagStateCodes, setPluginNameByCount, setStatusCode } = crmSlice.actions;

export default crmSlice.reducer;
