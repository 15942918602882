import { Box, AppBar, Tabs, Tab, Typography, Tooltip, IconButton } from "@mui/material"
import React, { useState } from "react";
import { useTheme } from '@mui/material/styles';
import { LineChartComponent } from "./LineChartComponent";
import { ExceptionMessageChart } from "./ExceptionMessageChart";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarQuickFilter, GridValueGetterParams } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getAllStateCodes, getCrmFromDate, getCrmToDate, getExMessagesByPluginName, getPluginExceptionLoadingStatus } from "../store/storeSelectors";
import dayjs from "dayjs";
import { setExByMessages } from "../store/crmSlice";
import { ContentCopy } from "@mui/icons-material";


interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

interface tabProps {
    pluginCount: any[]
    exMessage: any[]
    plExceptionResult: any[]
}



function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export const PlExTabs = ({pluginCount, exMessage, plExceptionResult}: tabProps) => {
    const [value, setValue] = useState(0);
    const theme = useTheme();
    const isLoading = useSelector(getPluginExceptionLoadingStatus);
    const fromDate = useSelector(getCrmFromDate);
    const toDate = useSelector(getCrmToDate);
    const exMessageByPlugins: any = useSelector(getExMessagesByPluginName);
    const allStateCodes: any = useSelector(getAllStateCodes);

    const dispatch = useDispatch();
    const [pluginName, setPluginName] = useState('');

    const columns: GridColDef[] = [
      {
        field: "organizationId",
        headerName: "Program",
        disableColumnMenu: true,
        sortable: true,
        minWidth: 100,
        headerClassName: "super-app-theme--header",
        valueGetter: (params: GridValueGetterParams<any, string>) => {
          return params?.value && getStateName(params?.value)
        },
      },
      {
        field: "correlationId",
        headerName: "CorrelationId",
        disableColumnMenu: true,
        sortable: true,
        minWidth: 260,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "timeGenerated",
        headerName: "Timestamp",
        align: "left",
        headerAlign: "left",
        minWidth: 220,
        headerClassName: "super-app-theme--header",
        disableColumnMenu: true,
        sortable: true,
        valueGetter: (params: GridValueGetterParams<any, number>) => {
          return params?.value && new Date(params.value).toLocaleString()
        },
      },
      {
        field: "primaryEntity",
        headerName: "Primary Entity",
        align: "left",
        headerAlign: "left",
        headerClassName: "super-app-theme--header",
        disableColumnMenu: true,
        sortable: true,
        minWidth: 220,
  
      },
      {
        field: "pluginName",
        headerName: "Plugin Name",
        align: "left",
        headerAlign: "left",
        headerClassName: "super-app-theme--header",
        disableColumnMenu: true,
        sortable: true,
        flex: 0.5,
      },
      {
        field: "exceptionDetails",
        headerName: "Exception Details",
        headerClassName: "super-app-theme--header",
        disableColumnMenu: true,
        align: "left",
        headerAlign: "left",
        sortable: false,
        flex: 1,
  
      },
    ];

    const handleChange = async (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <Box style={{ flex: 1 }}></Box>
          <GridToolbarQuickFilter />
        </GridToolbarContainer>
      );
    }

    const onDataPointClick = (item: any) => {
      let count: any = []
      const pluginName = item.name;
      Object.keys(exMessageByPlugins).map((plugin: string) => {
        if(plugin === pluginName) {          
          setPluginName(pluginName);
          return Object.entries(exMessageByPlugins[pluginName])?.map(([name, value]) => {
              count.push({
                name,
                value,
                pluginName
              })
          })
        }
      })
      dispatch(setExByMessages(count))
    }

    const getStateName = (orgName: string) => {
      let stateName = ''
      allStateCodes.forEach((item: any) => {
          if (item.organizationId === orgName) {
              stateName = item.abbreviation
          }
      })
      return stateName;
  }
    
    return <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
        <AppBar position="static">
            <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                TabIndicatorProps={{ style: { background: 'white' } }}
            >
                <Tab label="Grouped Result" {...a11yProps(0)} />
                <Tab label="Result" {...a11yProps(1)} />
            </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
            <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}> 
                <Box>
                    <Typography variant="h6" component="h1" py={2} textAlign={dayjs(toDate).diff(dayjs(fromDate), 'days') === 0 ? 'start' : 'center'}>
                        Exception count by Plugin/Date
                    </Typography>
                    {pluginCount.length ? dayjs(toDate).diff(dayjs(fromDate), 'days') === 0 ? <ExceptionMessageChart pieData={pluginCount} onClick={onDataPointClick} />:  <LineChartComponent data={pluginCount} onClick={onDataPointClick}/> : null }
                </Box>
                <Box>
                  <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center'}}> 
                    <Typography variant="h6" component="h1" py={2}>
                        {`Exception count by Message ${pluginName && ': ' +pluginName}`}
                    </Typography>
                    {pluginName && <IconButton onClick={() => navigator.clipboard.writeText(pluginName)} size="medium" sx={{ ml: 1 }}>
                      <ContentCopy />
                    </IconButton> }
                  </Box>
                    {exMessage.length ?  <ExceptionMessageChart pieData={exMessage} /> : null }
                </Box>
            </Box>
        </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>

      <DataGrid
        density="standard"
        autoHeight
        rows={isLoading ? [] : plExceptionResult}
        loading={isLoading}
        columns={columns}
        getRowId={(row) => row.timeGenerated + crypto.randomUUID()}
        sx={{
          fontSize: 12,
          "& .super-app-theme--header": {
            backgroundColor: "#4A2E62",
            color: "white",
          },
          "& .MuiDataGrid-sortIcon": {
            opacity: 1,
            color: "white",
          },
        }}
        slots={{
          toolbar: CustomToolbar,
        }}
        pagination
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          }
        }}
        pageSizeOptions={[5, 10, 15, 20, 50]}
      />   
      </TabPanel>
    </Box>
}

export const PluginExceptionTabs = React.memo(PlExTabs);