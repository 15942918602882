import { PlayArrow } from "@mui/icons-material";
import { Backdrop, Box, Button, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarQuickFilter, GridValueGetterParams } from "@mui/x-data-grid";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "../../components/Calendar";
import { CrmExceptionsChart } from "../../components/CrmExceptionsChart";
import { fetchCrmClientSideExceptions } from "../../network/apiCalls";
import { setCRMClientSideExceptions, setClentSideExceptionStateCode, setClientSideExceptionLoader, setClientSideExceptionsByProgram, setClientSideExceptionsCountByDate, setFilteredClientSideExceptions } from "../../store/crmSlice";
import { setOrganisationName } from "../../store/dropdownSlice";
import {
    getAllStateCodes, getClientSideExceptionLoadingStatus,
    getClientSideExceptionStateCode,
    getClientSideExceptions, getClientSideExceptionsByPrograms,
    getClientSideExceptionsCountByDate,
    getEnv, getFilteredClientSideExceptions, getStateCodeOrgName
} from "../../store/storeSelectors";
import { getClientSideExByPrgram, getClientSideExCountByDate } from "../../utils/CRM/PluginExceptions";
import { LoadingPage } from "../LoadingPage";

export const ClientSideExceptions = () => {
    const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs().startOf('date'))
    const [toDate, setToDate] = useState<Dayjs | null>(dayjs().startOf('date').add(1, 'hour'));
    const selectedEnv = useSelector(getEnv);
    const programExceptions: any = useSelector(getClientSideExceptionsByPrograms);
    const countByDateExceptions: any = useSelector(getClientSideExceptionsCountByDate);
    const clientSideExceptions: any = useSelector(getClientSideExceptions)
    const filteredclientSideExceptions = useSelector(getFilteredClientSideExceptions)
    const selectedCEStateCode = useSelector(getClientSideExceptionStateCode);
    const organizationName = useSelector(getStateCodeOrgName);
    const allStateCodes: any = useSelector(getAllStateCodes);

    const showLoader = useSelector(getClientSideExceptionLoadingStatus);

    const dispatch = useDispatch();

    const getStateName = (orgName: string) => {
        let stateName = ''
        allStateCodes.forEach((item: any) => {
            if (item.organizationName === orgName) {
                stateName = item.abbreviation
            }
        })
        return stateName;
    }

    const columns: GridColDef[] = [
        {
            field: "organizationName",
            headerName: "Organization Name",
            disableColumnMenu: true,
            sortable: true,
            minWidth: 160,
            headerClassName: "super-app-theme--header",
            valueGetter: (params: GridValueGetterParams<any, string>) => {
                return params?.value && getStateName(params?.value)
            },
        },
        {
            field: "timeGenerated",
            headerName: "Timestamp",
            align: "left",
            headerAlign: "left",
            minWidth: 220,
            headerClassName: "super-app-theme--header",
            disableColumnMenu: true,
            sortable: true,
            valueGetter: (params: GridValueGetterParams<any, number>) => {
                return params?.value && new Date(params.value).toLocaleDateString()
            },
        },
        {
            field: "errorMessage",
            headerName: "Error Message",
            headerAlign: "left",
            headerClassName: "super-app-theme--header",
            disableColumnMenu: true,
            sortable: false,
            align: "left",
            flex: 0.5,
        },
        {
            field: "fileName",
            headerName: "Filename",
            align: "left",
            headerAlign: "left",
            minWidth: 240,
            headerClassName: "super-app-theme--header",
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: "lineNumber",
            headerName: "Line No",
            align: "left",
            headerAlign: "left",
            headerClassName: "super-app-theme--header",
            disableColumnMenu: true,
            sortable: false,
            minWidth: 120,
        },
        {
            field: 'errorCount',
            headerName: "Error count",
            align: "center",
            headerAlign: "center",
            headerClassName: "super-app-theme--header",
            disableColumnMenu: true,
            sortable: false,
            minWidth: 120
        },
    ];

    useEffect(() => {
        dispatch(setCRMClientSideExceptions([]));
        dispatch(setFilteredClientSideExceptions([]));
        dispatch(setClientSideExceptionsByProgram([]));
        dispatch(setClientSideExceptionsCountByDate([]));
        dispatch(setOrganisationName(''));
        dispatch(setClentSideExceptionStateCode(''));

    }, [dispatch])

    const fetchClientSideExceptions = async () => {
        dispatch(setClientSideExceptionLoader(true));
        const exceptionsResult = await fetchCrmClientSideExceptions(selectedEnv, organizationName, fromDate?.toISOString()!, toDate?.toISOString()!, true);
        dispatch(setCRMClientSideExceptions(exceptionsResult));
        exceptionsResult && getClientSideExByPrgram(exceptionsResult, allStateCodes);
        exceptionsResult && getClientSideExCountByDate(exceptionsResult);
        dispatch(setFilteredClientSideExceptions(exceptionsResult));
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Box style={{ flex: 1 }}></Box>
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }

    return <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {
            showLoader && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showLoader}
            >
                <LoadingPage />
            </Backdrop>
        }
        <Typography variant="h6" component="h1" pb={2}>
            {`Clientside Exceptions for ${selectedCEStateCode ? selectedCEStateCode + ' program' : 'all programs'}`}
        </Typography>
        <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            <Calendar label='From' value={fromDate} onChange={setFromDate} maxDate={dayjs()} minDate={dayjs(toDate).subtract(30, 'days')} />
            <span style={{ padding: '5pt' }} />
            <Calendar label='To' value={toDate} onChange={setToDate} maxDate={dayjs()} />
            <Button variant="contained" startIcon={<PlayArrow />} sx={{ ml: 2 }}
                onClick={fetchClientSideExceptions}
                disabled={!allStateCodes}
            >
                Run
            </Button>
        </Box>
        <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
            {programExceptions ? <CrmExceptionsChart data={programExceptions} label={'Exception count by Program'} xAxisKey='state' dataKey='count' /> : null}
            {programExceptions ? <CrmExceptionsChart data={countByDateExceptions} label={'Exception count by Date'} xAxisKey='date' dataKey='count' /> : null}
        </Box>
        <DataGrid
            density="standard"
            autoHeight
            rows={showLoader ? [] : filteredclientSideExceptions?.length ? filteredclientSideExceptions : (!filteredclientSideExceptions?.length && selectedCEStateCode) ? [] : clientSideExceptions}
            loading={showLoader}
            columns={columns}
            getRowId={(row) => row.errorMessage + row.errorCount}
            sx={{
                fontSize: 12,
                "& .super-app-theme--header": {
                    backgroundColor: "#4A2E62",
                    color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                    opacity: 1,
                    color: "white",
                },
            }}
            slots={{
                toolbar: CustomToolbar,
            }}
            pagination
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                }
            }}
            pageSizeOptions={[5, 10, 15, 20, 50]}
        />
    </Box>
}