import { AppState } from "./store";

export const getSelectedStateCodes = (state: AppState) =>
  state.dropdowns.selectedStateCodes;

export const getStateCodes = (state: AppState) => state.dropdowns.stateCodes;

export const getCheckedState = (state: AppState) =>
  state.dropdowns.checkedState;

export const getEBTStateCodes = (state: AppState) => state.dropdowns.ebtStateCodes;

export const getAllStateCodes = (state: AppState) => state.dropdowns.allStateCodes;

export const getStateCodeOrgId = (state: AppState) => state.dropdowns.orgId;

export const getStateCodeOrgName = (state: AppState) => state.dropdowns.orgName;

export const getBatchJobs = (state: AppState) => state.batchJobs.batchJobs;

export const getGraphData = (state: AppState) => state.batchJobs.graphData;

export const getLoadingStatus = (state: AppState) => state.batchJobs.isLoading;

export const getGraphLoadingStatus = (state: AppState) => state.batchJobs.graphLoader;

export const getFilteredJobs = (state: AppState) => state.batchJobs.filterBatchJobs;

export const getFilteredGraph = (state: AppState) => state.batchJobs.filteredGraphData;

export const getEnv = (state: AppState) => state.dropdowns.env;

export const getDateRange = (state: AppState) => state.dropdowns.dateRange;

export const getEBTHealth = (state: AppState) => state.ebt.health;

export const getEBTIssuance = (state: AppState) => state.ebt.issuance;

export const getEBTRedemption = (state: AppState) => state.ebt.redemption;

export const getFilteredIssuance = (state: AppState) => state.ebt.filteredIssuance;

export const getFilteredRedemption = (state: AppState) => state.ebt.filteredRedemption;

export const getEBTLoadingStatus = (state: AppState) => state.ebt.isLoading;

export const getEBTStateCode = (state: AppState) => state.ebt.ebtStateCode;

export const getEBTTabIndex = (state: AppState) => state.ebt.ebtTab;

export const getMyWicHealth = (state: AppState) => state.myWic.health;

export const getMyWicServices = (state: AppState) => state.myWic.services;

export const getMyWicLoadingStatus = (state: AppState) => state.myWic.isLoading;

export const getCRMFlags = (state: AppState) => state.crm.flags;

export const getCRMEnvDetails = (state: AppState) => state.crm.envDetails;

export const getCRMLoadingStatus = (state: AppState) => state.crm.isLoading;

export const getCRMFFStateCode = (state: AppState) => state.crm.crmFF;

export const getCRMTableLoadingStatus = (state: AppState) => state.crm.tableLoading;

export const getUnavailableFlags = (state: AppState) => state.crm.unavailFlags;

export const getFeatFlagStateCodes = (state: AppState) => state.crm.featFlagStateCodes;

export const getPluginExceptionLoadingStatus = (state: AppState) => state.crm.pluginExceptionsLoading;

export const getPluginExceptionStateCode = (state: AppState) => state.crm.crmPluginStateCode;

export const getPluginExceptions = (state: AppState) => state.crm.pluginExceptions;

export const getFilteredPluginExceptions = (state: AppState) => state.crm.filteredPluginExceptions;

export const getExByPrograms = (state: AppState) => state.crm.exByProgram;

export const getExByDates = (state: AppState) => state.crm.exByDate;

export const gettExByPlugins = (state: AppState) => state.crm.exByPlugin;

export const getExByMessages = (state: AppState) => state.crm.exByMessage;

export const getExMessagesByPluginName = (state: AppState) => state.crm.exMessageByPluginName;

export const getPluginNamesByCount = (state: AppState) => state.crm.pluginNameByCount;

export const getCrmFromDate = (state: AppState) => state.crm.fromDate;

export const getCrmToDate = (state: AppState) => state.crm.toDate;

export const getPlExceptionStateCodes = (state: AppState) => state.crm.PlExceptionStateCodes;

export const getClientSideExceptions = (state: AppState) => state.crm.crmClientSideExceptions;

export const getClientSideExceptionsByPrograms = (state: AppState) => state.crm.clientSideExceptionsByProgram;

export const getClientSideExceptionsCountByDate = (state: AppState) => state.crm.clientSideExceptionsCountByDate;

export const getClientSideExceptionLoadingStatus = (state: AppState) => state.crm.clientSideExceptionsLoading;

export const getFilteredClientSideExceptions = (state: AppState) => state.crm.filteredClientSideExceptions;

export const getClientSideExceptionStateCode = (state: AppState) => state.crm.crmClientSideStateCode;

export const getClientSideExceptionFromDate = (state: AppState) => state.crm.clientSideFromDate;

export const getClientSideExceptionToDate = (state: AppState) => state.crm.clientSideToDate;

export const  getPluginExceptionStateCodesLoadingStatus = (state: AppState) => state.crm.plExceptionStateCodesLoading;

export const getPlExStatusCode = (state: AppState) => state.crm.plExStatusCode;



